import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './component/Header/Header';
import Hero from './component/Hero/Hero';
import Footer from './component/Footer/Footer';
import ErrorNotFoundPage from './component/ErrorPage/ErrorNotFoundPage';
import ErrorResolutionPage from './component/ErrorPage/ErrorResolutionPage';
import ScrollToTop from './action/ScrollToTop';
import './App.css';

function Home() {
    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content="A professional in UI/UX Design — Rahman Haryanto" />
                <title>A professional in UI/UX Design — Rahman Haryanto</title>
            </Helmet>
            <Header />
            <Hero />
            <Footer />
            <ErrorResolutionPage />
        </React.Fragment>
    )
}

function ErrorNotFound() {
    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content="Page Not Found" />
                <title>Page Not Found</title>
            </Helmet>
            <Header />
            <Footer />
            <ErrorNotFoundPage />
            <ErrorResolutionPage />
        </React.Fragment>
    )
}

class App extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route component={ErrorNotFound} />
                </Switch>
            </Router>
        );
    }
}

export default App;