import React from 'react';
import { Link } from 'react-router-dom';
import Clock from 'react-digital-clock';
import ReactTooltip from 'react-tooltip';
import './Header.css';

function Header() {
    return (
        <React.Fragment>
            <header className="header">
                <div className="header-logo">
                    <Link to="/">
                        <svg viewBox="0 0 93 64" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2904_15)"><path d="M20.6301 35.9467L8.58806 64.0027H0L12.418 35.0664C14.167 35.6398 16.035 35.9467 17.972 35.9467H20.6282H20.6301Z" fill="#1955FF"/><path d="M74.084 0H56.112V28.056H35.9479V0H17.9758C8.06065 0 0 8.06065 0 17.972C0 25.9464 5.22034 32.7182 12.418 35.0637C14.167 35.6372 16.035 35.944 17.972 35.944H28.0541V64H35.944V35.944H56.1081V64H63.9981V35.944H74.0801C83.9915 35.944 92.0521 27.8834 92.0521 17.972C92.0521 8.06065 83.9953 0 74.084 0ZM28.056 28.056H17.9739C12.4161 28.056 7.89188 23.5318 7.89188 17.9739C7.89188 12.4161 12.4161 7.89188 17.9739 7.89188H28.056V28.056ZM74.084 28.056H64.0019V7.88996H74.084C79.6419 7.88996 84.166 12.4141 84.166 17.972C84.166 23.5299 79.6419 28.0541 74.084 28.0541V28.056Z" fill="#FFFFFF"/></g></svg>
                    </Link>
                </div>
                <div className="header-clock">
                    <h4 className="h4-heading-regular"
                        data-tip='Local Time'
                        data-for='headerTime'>
                        <Clock
                            hour12={false}
                            format={'hh-mm'}
                        />
                    </h4>
                    <ReactTooltip
                        id='headerTime'
                        type='dark'
                        effect='solid'
                        place='left'
                    />
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header;