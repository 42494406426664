import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <React.Fragment>
            <footer className="footer">
                <span className="caption-regular">RHP © 2021</span>
            </footer>
        </React.Fragment>
    )
}

export default Footer;