import React from 'react';
import './ErrorPage.css';

function ErrorResolutionPage() {
    return (
        <React.Fragment>
            <section className="container-fluid error resolution">
                <div className="container error-inner">
                    <h2 className="h2-heading-bold">Please kindly increase resolution of the browser.</h2>
                    <h4 className="h4-heading-regular">For better experience</h4>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ErrorResolutionPage;