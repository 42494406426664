import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.css';

function ErrorNotFoundPage() {
    return (
        <React.Fragment>
            <section className="container-fluid error not-found">
                <div className="container error-inner">
                    <h2 className="h2-heading-bold">Looks like what you want isn’t founded.</h2>
                    <h4 className="h4-heading-regular">
                        <Link to="/">Back to home</Link>
                    </h4>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ErrorNotFoundPage;